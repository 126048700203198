@import "../../theme/variables";

.app-component {
	// display: inline-block !important;
	height: 80% !important;
	width: 80% !important;
}

@media only screen and (max-width: 640px) {
	.app-component {
		width: 95% !important;
	}
}

.app-topbar {
	height: 32px !important;
	width: 100% !important;
	color: var(--font-white) !important;
	border-bottom-right-radius: unset !important;
	border-bottom-left-radius: unset !important;
}

.close-button:hover {
	color: var(--color-white);
	background-color: var(--color-red);
}

.title-bar {
	width: calc(100% - 140px) !important;
}

.app-top-functions {
	width: 100px !important;
}

.maximized {
	// margin-left: -10% !important;
	width: 100% !important;
	height: calc(100% - 40px) !important;
}

.app-content-container {
	height: 100% !important;
	width: 80% !important;
}

.maximized-application {
	height: 100% !important;
	width: 100% !important;
}

.app-sidebar {
	height: 100% !important;
	width: 20% !important;
}

.app-content {
	height: calc(100% - 32px) !important;
	width: 100% !important;
	overflow-y: scroll !important;
	overflow-x: hidden !important;
}

.scrollbar::-webkit-scrollbar {
	width: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
	background-color: #babac0;
	border-radius: 16px;
}

.scrollbar::-webkit-scrollbar-button {
	display: none;
}

.app-title {
	border-bottom-right-radius: unset !important;
	border-bottom-left-radius: unset !important;
	width: 200px !important;
	height: 100% !important;
}

.sidebar-list {
	color: var(--font-white) !important;
}

.sidebar-list-item {
	cursor: pointer !important;
	padding: 10px !important;
}

.sidebar-list :hover {
	background-color: rgb(50, 49, 48) !important;
}

.sidebar-list {
	.uk-active {
		background-color: rgb(50, 49, 48) !important;
		border-left: 5px solid;
		border-color: var(--color-blue) !important;
		font-weight: bold;
	}
}

.app-nav-bar {
	width: 100%;
	margin-left: 0px !important;
	height: 25px !important;
}

.disabled-text-field {
	.ms-TextField-wrapper {
		.ms-TextField-fieldGroup {
			.ms-TextField-field {
				background: none rgb(50, 49, 48) !important;
				color: rgb(121, 119, 117) !important;
				border: none rgb(50, 49, 48) !important;
			}
			border: 0px !important;
		}
	}
}

.isApplication {
	display: none !important;
}

.isApplication-list {
	height: 96% !important;
}

.dropdown-content {
	position: absolute;
	min-width: 160px;
	z-index: 10000;
}

.show-element {
	display: block !important;
}

.hide-element {
	display: none !important;
}
