@import "../../theme/variables";

.taskbar-bottom {
	height: 40px !important;
	background: rgba(0, 0, 0, 0.55);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(20px);
}

.taskbar-icon {
	color: var(--color-white);
	width: 45px;
	margin-right: 5px;
}

.taskbar-quick-icon {
	color: var(--color-white);
	width: 30px;
	margin-right: 5px;
}

.taskbar-quick-icon:hover {
	color: var(--color-white);
	cursor: pointer !important;
	background-color: rgba(255, 255, 255, 0.1) !important;
}
.taskbar-icon:hover {
	color: var(--color-white);
	cursor: pointer !important;
	background-color: rgba(255, 255, 255, 0.1) !important;
}

.taskbar-icon-active {
	height: 90% !important;
	border-bottom: 1px solid #ccc;
	border-color: var(--color-blue) !important;
}

.onDesktop {
	background-color: rgba(255, 255, 255, 0.3) !important;
}

.onDesktop:hover {
	background-color: rgba(255, 255, 255, 0.5) !important;
}

.desktop-container {
	height: calc(100% - 40px) !important;
}

.taskbar {
	height: 40px !important;
}
