@import "../../theme/variables";

.taskbar-clock {
	height: 40px !important;
	width: unset !important;
	margin-right: unset !important;
}

.clock-timer {
	font-size: 12px !important;
	color: var(--font-white) !important;
}

.app-icon:hover {
	background: rgba(74, 74, 74, 0.5) !important;
	backdrop-filter: blur(20px) !important;
	-webkit-backdrop-filter: blur(20px) !important;
}
