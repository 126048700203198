@import "../../../theme/variables";

.container {
	position: relative;
	overflow: hidden;
}

.container:before {
	content: "";
	position: absolute;
	top: 0;
	left: 10px;
	margin-left: -1px;
	width: 2px;
	height: 100%;
	background: #ccd1d9;
	z-index: 1;
}

.timeline-block {
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-moz-box-pack: justify;
	justify-content: space-between;
	clear: both;
}

.timeline-block-right {
	float: right;
}

.marker {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 2px solid #f5f7fa;
	background: #4fc1e9;
	margin-top: 10px;
	z-index: 9999;
}

.timeline-content {
	width: 95%;
	padding: 0 15px;
}

.timeline-content span {
	font-size: 15px;
	color: var(--font-light-grey);
}

.positon-desc {
	font-size: 14px;
	line-height: 1.5em;
	word-spacing: 1px;
	color: var(--font-light-grey);
}

@media screen and (max-width: 768px) {
	.container:before {
		left: 8px;
		width: 2px;
	}
	.timeline-block {
		width: 100%;
		margin-bottom: 30px;
	}
	.timeline-block-right {
		float: none;
	}

	.timeline-block-left {
		float: none;
		direction: ltr;
	}
}

.org-img {
	height: 75px !important;
}

.project-card {
	background: rgb(50, 49, 48) !important;
}

.link-to-project {
	color: var(--font-color-white);
	.ms-Button-flexContainer {
		.ms-Button-icon {
			font-size: 20px !important;
		}
	}
}

.link-to-project:hover {
	color: var(--color-blue);
	background-color: rgb(50, 49, 48) !important;
}

.mail-card {
	border: 2px solid #f5f7fa;
}
