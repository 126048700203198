:root {
	--color-primary: #000000;
	--color-white: #ffffff;
	--color-red: #ff0000;
	--color-blue: #00a2ed;
	--color-secondary: rgb(34, 34, 34);

	--font-white: rgb(250, 249, 248);
	--font-light-grey: #a4a4a4;

	--font-medium: 18px !important;

	--medium-screen: 640px !important;
	--small-screen: 960px;
}
